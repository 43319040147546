.conteudoRodape {
  width: 74.88vw;
  margin: 0 auto;
  height: 75px;
  background-color: #ffffff;
}

.conteudoRodape p {
  color: #333;
  font-family: Nunito;
  font-size: 1.17vw;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 140%;
  margin-top: -1.625vw;
  margin-left: 0.5rem;
}

@media screen and (min-width: 2559px) and (max-width: 2561px) and (min-height: 1079px) and (max-height: 1081px) {
  .conteudoRodape p {
    margin-top: -0.75vw;
    
  }
}
