@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  background: #FFFFFF;
}


header {
  background: #005DF9
}

.conteudoCabecalho {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 10.16vw 1.5625rem 10.16vw;
}
.conteudoCabecalho img 
{
    width: 13.44vw;
    height: auto;
}
.tituloCabecalho {
  color: #fff;
  font-family: Nunito;
  font-size: 2.47vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.conteudoRodape {
  width: 74.88vw;
  margin: 0 auto;
  height: 75px;
  background-color: #ffffff;
}

.conteudoRodape p {
  color: #333;
  font-family: Nunito;
  font-size: 1.17vw;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 140%;
  margin-top: -1.625vw;
  margin-left: 0.5rem;
}

@media screen and (min-width: 2559px) and (max-width: 2561px) and (min-height: 1079px) and (max-height: 1081px) {
  .conteudoRodape p {
    margin-top: -0.75vw;
    
  }
}

.titulo {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1.75rem;

  color: #005df9;
}

.containerTituloPlanos {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr;

  grid-template-areas: ". preco precoCartaoDrc valorEconomia";
  justify-items: center;
  align-items: center;
  overflow: hidden;
  width: 74.88vw;
  margin: 0 auto;
}

.tituloPlano {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;

  color: #222222;
  border-radius: 1rem;
  padding: 0.25rem 1.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.tituloPrecoSemCartaoDrc {
  grid-area: preco;
}

.tituloPrecoSemCartaoDrc span {
  color: #0a116d;
}

.tituloPrecoCartaoDrc {
  grid-area: precoCartaoDrc;
}
.tituloPrecoCartaoDrc span {
  color: #fff;
  background: #005df9;
}

.tituloPrecoCartaoDrc span,
.tituloValorEconomia span {
  padding: 0.421172vw 1.05vw;
  display: inline-flex;
  border-radius: 1.68vw;
}

.tituloValorEconomia {
  grid-area: valorEconomia;
}
.tituloValorEconomia span {
  background: #01efae;
}

.tituloPrecoSemCartaoDrc,
.tituloPrecoCartaoDrc,
.tituloValorEconomia {
  font-family: Nunito;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.34vw; /* 106.238% */
}
.containerSlider .produtos:nth-child(2) .colunas .borderContainer {
  border-top-left-radius: 1.33vw;
  border-top-right-radius: 1.33vw;
}
.containerSlider .produtos:nth-last-child(2) .colunas .borderContainer {
  border-bottom-left-radius: 1.33vw;
  border-bottom-right-radius: 1.33vw;
  height: 17.89vh;
}

.economiaContainer,
.precoComCartaoContainer,
.precoSemComCartaoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.97vw;
  height: 13.89vh;
}

.precoSemComCartaoContainer {
  background-color: #f4f4f4;
}
.precoComCartaoContainer {
  background-color: #005df9;
}
.economiaContainer {
  background-color: #b2fae7;
}

.formatacaoPrecoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.68vw;
}
.cifraoEconomia {
  color: #0a116d;
  font-family: Nunito;
  font-size: 1.33vw;
  font-style: normal;
  font-weight: 400;
  line-height: 2.7462rem; /* 250.602% */
}

.valorEconomiaTexto {
  color: #0a116d;
  font-family: Nunito;
  font-size: 3.68vw;
  font-style: normal;
  font-weight: 800;
  vertical-align: baseline;
  line-height: 1rem; /* 93.333% */
  height: 3.68vw;
  display: flex;
  align-items: center;
}
.casasDecimaisEconomia {
  color: #0a116d;
  font-family: Nunito;
  font-size: 1.33vw;
  font-style: normal;
  font-weight: 800;
  margin-left: 0.1vw;
  align-self: flex-end;
  height: 1.33vw;
}
@media only screen and (max-width: 1921px) {
  .casasDecimaisEconomia {
    position: relative;
    bottom: 0.3vw;
  }
}
.naoCobertoAzul {
  color: #0a116d;
}
.naoCobertoBranco,
.incluso {
  color: #fff;
}

.naoCoberto,
.incluso {
  font-family: Nunito;
  font-size: 1.71vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

/* Slider */
.container {
  min-width: 100%;
  position: absolute;
  left: 0;
  top: 15.25%;
  overflow: hidden;
}

.containerProdutos {
  width: 100%;
  display: flex;
}

.containerSlider {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}

/* Fim Slider */

.produtos {
  width: 74.88vw;
  margin: 0 auto;
  border-bottom: 2px solid #c7c7c7;
}
.produtos:nth-last-child(2) {
  border: none;
}

.conteudoProdutos {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr 1fr;
  grid-template-areas: "nomeProduto preco precoCartaoDrc valorEconomia";
  justify-items: center;
  align-items: center;
  overflow: hidden;
  width: 74.88vw;
  grid-gap: 0 0.625vw;
  gap: 0 0.625vw;
  margin: 0 auto;
}
.colunas {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex: 1 1;
  width: 17.97vw;
}

.colunas h2 {
  margin: 0;
}

.tituloProduto {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #333;
  font-family: Nunito;
  font-size: 1.71vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: left;

  width: 17.97vw;
  height: 13.89vh;
}
@media screen and (min-width: 2559px) and (max-width: 2561px) and (min-height: 1079px) and (max-height: 1081px) {
  .tituloProduto 
  {
    font-size: 1.41vw;
  }
}
.containerPreco {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.75rem;
}

.preco {
  grid-area: "preco";

  color: #222222;
}

.containerPreco p {
  margin-top: 0;
  margin-bottom: 0;
}

.cifrao {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  line-height: 1.25rem;
}

.valorProduto {
  font-size: 1.875rem;
  line-height: 1.75rem;
  margin-right: 0.75rem;
}

.quantidadeParcelas {
  margin-right: 0.375rem;
}

.numeroParcelas {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-right: 0.5rem;
}

.valorParcelas {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.precoCartaoDrc {
  grid-area: "precoCartaoDrc";
  display: flex;
}

.precoComCartaoDrcItemsContainer,
.precoSemCartaoDrcItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.precoCartaoDrcContent,
.precoSemCartaoDrcContent {
  display: flex;
  align-items: center;
}

.cifraoCartaoDrc {
  color: #fff;
}

.cifraoCartaoDrc,
.cifraoSemCartaoDrc {
  font-family: Nunito;
  font-size: 1.33vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.44vw; /* 250.602% */
}

.valorCartaoDrc {
  color: #fff;
}

.valorCartaoDrc,
.valorSemCartaoDrc {
  font-family: Nunito;
  font-size: 3.67vw;
  font-style: normal;
  font-weight: 800;
  line-height: 3.44vw; /* 93.333% */
}
.casasDecimaisCartaoDrc {
  color: #fff;
}
.casasDecimaisCartaoDrc,
.casasDecimaisSemCartaoDrc {
  font-family: Nunito;
  font-size: 1.33vw;
  font-style: normal;
  font-weight: 800;
  height: 1.33vw;
  align-self: flex-end;
  margin-bottom: 0.225vw;
}

.emAtePrecoCartaoDrc {
  color: #fff;
}

.emAtePrecoCartaoDrc,
.emAtePrecoSemCartaoDrc {
  text-align: center;
  font-family: Nunito;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 400;
  line-height: 111.4%; /* 0.93038rem */
}

.parcelasPrecoCartaoDrc {
  color: #fff;
}

.parcelasPrecoCartaoDrc,
.parcelasPrecoSemCartaoDrc {
  font-family: Nunito;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 800;
  line-height: 111.4%;
}
.coberturaCartaoDrc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;

  color: #005df9;
}


