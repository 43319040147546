header {
  background: #005DF9
}

.conteudoCabecalho {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.25rem 10.16vw 1.5625rem 10.16vw;
}
.conteudoCabecalho img 
{
    width: 13.44vw;
    height: auto;
}
.tituloCabecalho {
  color: #fff;
  font-family: Nunito;
  font-size: 2.47vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
